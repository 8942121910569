/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.wrapper {
  max-height: calc(100vh - 96px);
}

@media (max-width: 1680px) {
  .wrapper {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .preview {
    grid-column: 2/3;
    margin-top: 50px;
  }
}

.sidebar-content {
  @apply overflow-y-auto px-6;

  min-height: calc(100% - 68px - 74px);
  max-height: calc(100% - 68px - 74px);
}

.portal-preview {
  @apply w-full max-h-full overflow-hidden;
}

.portal-preview iframe {
  @apply h-full w-full pointer-events-none;
  overflow: hidden;
}

table.poc {
  @apply w-full border-collapse;
}
table.poc thead tr th {
  @apply py-3 px-4 bg-gray-200 font-bold uppercase text-sm text-gray-700 border-b border-gray-300 text-left;
}
table.poc tbody tr td {
  @apply py-3 px-4 border-b border-r border-gray-200;
}
table.poc tbody tr td:first-of-type {
  width: 20px;
}
table.poc tbody tr td.selected,
td:focus {
  outline: 2px solid #794cff;
  outline-offset: -2px;
  caret-color: transparent;
}
table.poc tbody tr td:last-child {
  @apply border-r-0;
}

.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input {
  background: transparent;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.validator-menu span {
  margin-bottom: 4px;
}

.info-icon:hover {
  fill: #794cff;
}

.tooltip {
  @apply invisible absolute;
  left: 20px;
  top: 0;
  background: white;
  padding: 4px;
}

.has-tooltip {
  height: 100px;
  position: absolute;
  top: 0;
  background: blue;
}

.has-tooltip:hover {
  cursor: pointer;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
  /* height: 100px;
  position: absolute;
  top: 0;
  background: blue; */
}
